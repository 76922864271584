// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-column-title{
  color: #fff;
  font-size: 20px;
}

@media (max-width: 768px) {
    .footer-v2 .contact-info,
    .footer-v2 .footer-menu,
    .footer-v2 #back-to-top,
    .footer-v2 .logo-container {
      display: none;
    }
    
    .footer-v2 .ft-list-icon,
    .footer-v2 .copyright-text {
      text-align: center;
      margin-top: 10px;
    }

    .space-90, .space-60, .space-65, .space-20{
        display: none;
    }

    .site-footer{
        padding: 30px 0;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;IACI;;;;MAIE,aAAa;IACf;;IAEA;;MAEE,kBAAkB;MAClB,gBAAgB;IAClB;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,eAAe;IACnB;EACF","sourcesContent":[".footer-column-title{\r\n  color: #fff;\r\n  font-size: 20px;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n    .footer-v2 .contact-info,\r\n    .footer-v2 .footer-menu,\r\n    .footer-v2 #back-to-top,\r\n    .footer-v2 .logo-container {\r\n      display: none;\r\n    }\r\n    \r\n    .footer-v2 .ft-list-icon,\r\n    .footer-v2 .copyright-text {\r\n      text-align: center;\r\n      margin-top: 10px;\r\n    }\r\n\r\n    .space-90, .space-60, .space-65, .space-20{\r\n        display: none;\r\n    }\r\n\r\n    .site-footer{\r\n        padding: 30px 0;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
