// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.align-self-center{
    align-items: center;
}

.tech-box{
    margin: 0 !important;
    min-width: 150px;
}

.technology-index-cards{
    display: flex;
}

.tech-box-grid{
    display: flex;
    align-items: center;
}

.about-section{
    padding: 0px;
}

.learn-more-button a{
    text-decoration: none;
    background-color: #0077cc;
    color: #fff;
    padding: 20px;
    font-weight: 600;
    border: 1px solid #0077cc;
}

.learn-more-button a:hover{
    cursor: pointer;
    background-color: #fff;
    color: #0077cc;
}

.project-details-button{
    background-color: #0077cc;
    color: #fff;
    font-weight: 600;
    padding: 20px;
    text-wrap: nowrap;
    border: 1px solid #0077cc;
}

.project-details-button:hover{
    background-color: #fff;
    text-decoration: none;
    color: #0077cc;
    border: 1px solid #0077cc;
}

@media (max-width: 580px) {
    .hide-projects {
      display: none !important;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Home.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,yBAAyB;IACzB,WAAW;IACX,aAAa;IACb,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;IACtB,qBAAqB;IACrB,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI;MACE,wBAAwB;IAC1B;EACF","sourcesContent":[".align-self-center{\r\n    align-items: center;\r\n}\r\n\r\n.tech-box{\r\n    margin: 0 !important;\r\n    min-width: 150px;\r\n}\r\n\r\n.technology-index-cards{\r\n    display: flex;\r\n}\r\n\r\n.tech-box-grid{\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.about-section{\r\n    padding: 0px;\r\n}\r\n\r\n.learn-more-button a{\r\n    text-decoration: none;\r\n    background-color: #0077cc;\r\n    color: #fff;\r\n    padding: 20px;\r\n    font-weight: 600;\r\n    border: 1px solid #0077cc;\r\n}\r\n\r\n.learn-more-button a:hover{\r\n    cursor: pointer;\r\n    background-color: #fff;\r\n    color: #0077cc;\r\n}\r\n\r\n.project-details-button{\r\n    background-color: #0077cc;\r\n    color: #fff;\r\n    font-weight: 600;\r\n    padding: 20px;\r\n    text-wrap: nowrap;\r\n    border: 1px solid #0077cc;\r\n}\r\n\r\n.project-details-button:hover{\r\n    background-color: #fff;\r\n    text-decoration: none;\r\n    color: #0077cc;\r\n    border: 1px solid #0077cc;\r\n}\r\n\r\n@media (max-width: 580px) {\r\n    .hide-projects {\r\n      display: none !important;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
