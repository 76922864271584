// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-tech-wrap{
    display: flex;
    gap: 10px;
}
.about-tech-box {
display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.about-tech-box {
  display: block;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px 15px;
}
.about-tech-box .icon-main {
  line-height: 1;
  margin-bottom: 18px;
}
.about-tech-box i,
.about-tech-box span:before {
  font-size: 55px;
  line-height: inherit;
}
.about-tech-box h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.6;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #fff;
}
.about-tech-box:hover {
  background: #0077cc;
  border-color: #0077cc;
  color: #fff;
}

.about-tech-box-grid a h5 {
  font-size: 12px;
}

.about-tech-box i,
.about-tech-box span {
  color: #0077cc;
}

.about-tech-box:hover {
  background: #0077cc;
  border-color: #0077cc;
  color: #fff;
}
.about-tech-box:hover span {
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/pages/About.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;AACb;AACA;AACA,aAAa;EACX,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,WAAW;EACX,0CAA0C;EAC1C,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,mBAAmB;AACrB;AACA;;EAEE,eAAe;EACf,oBAAoB;AACtB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,yBAAyB;EACzB,WAAW;AACb;AACA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,WAAW;AACb;AACA;EACE,WAAW;AACb","sourcesContent":[".about-tech-wrap{\r\n    display: flex;\r\n    gap: 10px;\r\n}\r\n.about-tech-box {\r\ndisplay: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  margin-bottom: 30px;\r\n}\r\n.about-tech-box {\r\n  display: block;\r\n  color: #fff;\r\n  border: 1px solid rgba(255, 255, 255, 0.3);\r\n  padding: 10px 15px;\r\n}\r\n.about-tech-box .icon-main {\r\n  line-height: 1;\r\n  margin-bottom: 18px;\r\n}\r\n.about-tech-box i,\r\n.about-tech-box span:before {\r\n  font-size: 55px;\r\n  line-height: inherit;\r\n}\r\n.about-tech-box h5 {\r\n  font-size: 16px;\r\n  font-weight: 600;\r\n  line-height: 1.6;\r\n  margin-bottom: 0;\r\n  text-transform: uppercase;\r\n  color: #fff;\r\n}\r\n.about-tech-box:hover {\r\n  background: #0077cc;\r\n  border-color: #0077cc;\r\n  color: #fff;\r\n}\r\n\r\n.about-tech-box-grid a h5 {\r\n  font-size: 12px;\r\n}\r\n\r\n.about-tech-box i,\r\n.about-tech-box span {\r\n  color: #0077cc;\r\n}\r\n\r\n.about-tech-box:hover {\r\n  background: #0077cc;\r\n  border-color: #0077cc;\r\n  color: #fff;\r\n}\r\n.about-tech-box:hover span {\r\n  color: #fff;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
