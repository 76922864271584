// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Tooltip.css */
.custom-tooltip {
  background-color: rgb(0, 164, 185) !important;
  color: #fff !important;
  font-size: 0.875rem;
  padding: 6px 10px;
  border-radius: 4px;
  opacity: 0.9 !important;
  text-align: left;
  position: fixed;
  z-index: 9999;
  pointer-events: none;
  transition: opacity 0.1s ease-in-out; /* Smooth transition */
}
`, "",{"version":3,"sources":["webpack://./src/components/Tooltip/Tooltip.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,6CAA6C;EAC7C,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,uBAAuB;EACvB,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,oBAAoB;EACpB,oCAAoC,EAAE,sBAAsB;AAC9D","sourcesContent":["/* Tooltip.css */\r\n.custom-tooltip {\r\n  background-color: rgb(0, 164, 185) !important;\r\n  color: #fff !important;\r\n  font-size: 0.875rem;\r\n  padding: 6px 10px;\r\n  border-radius: 4px;\r\n  opacity: 0.9 !important;\r\n  text-align: left;\r\n  position: fixed;\r\n  z-index: 9999;\r\n  pointer-events: none;\r\n  transition: opacity 0.1s ease-in-out; /* Smooth transition */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
