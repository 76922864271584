// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tech-box-title {
  color: #fff;
  font-size: 20px;
}

.tech-box-section{
    display: flex;
    justify-content: center;
}

.tech-box-cards {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100% !important;
}

.tech-box-card {
  margin: 10px 0 0 0 !important;
}

@media (max-width: 500px) {
  .tech-box-cards {
    display: flex;
    flex-direction: column;
  }

  .tech-box{
    width: 200px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/SectionTechnologyIndex/SectionTechnologyIndex.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".tech-box-title {\r\n  color: #fff;\r\n  font-size: 20px;\r\n}\r\n\r\n.tech-box-section{\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n\r\n.tech-box-cards {\r\n  display: flex;\r\n  gap: 10px;\r\n  align-items: center;\r\n  width: 100% !important;\r\n}\r\n\r\n.tech-box-card {\r\n  margin: 10px 0 0 0 !important;\r\n}\r\n\r\n@media (max-width: 500px) {\r\n  .tech-box-cards {\r\n    display: flex;\r\n    flex-direction: column;\r\n  }\r\n\r\n  .tech-box{\r\n    width: 200px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
