import React from "react";
import { Link } from "react-router-dom";
import { useCart } from "../../context/CartContext";
import { useExchangeRate } from "../../context/ExchangeRateContext";
import "./ServicePricingCards.css";

const ServicePricingCards = () => {
  const { addToCart } = useCart(); // Access addToCart from context
  const { exchangeRates, currencyCode } = useExchangeRate(); // Access exchange rates and currency code

  // Define currency symbols for each currency code
  const currencySymbols = {
    USD: "$",
    EUR: "€",
    GBP: "£",
    ZAR: "R",
    AUD: "A$",
    CAD: "C$",
    JPY: "¥",
    CNY: "¥",
    CHF: "Fr",
    NZD: "$",
    SGD: "S$",
    HKD: "HK$",
    INR: "₹",
    BRL: "R$",
    MXN: "$",
    SEK: "kr",
    NOK: "kr",
    RUB: "₽",
    KRW: "₩",
    PLN: "zł",
    TRY: "₺",
    THB: "฿",
    IDR: "Rp",
    MYR: "RM",
    PHP: "₱",
    DKK: "kr",
    HUF: "Ft",
    CZK: "Kč",
    ILS: "₪",
    AED: "د.إ",
    SAR: "﷼",
    CLP: "$",
    COP: "$",
    PEN: "S/.",
    PKR: "₨",
    VND: "₫",
    BDT: "৳",
    NGN: "₦",
    EGP: "£",
    QAR: "﷼",
    KWD: "د.ك",
  };

  // Helper function to get the currency symbol based on currency code
  const getCurrencySymbol = (currencyCode) => {
    return currencySymbols[currencyCode] || "$";
  };

  // Function to convert ZAR to the desired currency
  const convertToCurrency = (amountInZAR) => {
    const conversionRate = exchangeRates[currencyCode] || 1;
    const amount = parseFloat(amountInZAR) || 0;

    return (amount * conversionRate).toFixed(2);
  };

  // Handle adding subscription plan to cart
  const handleAddSubscriptionToCart = (planName, price) => {
    const subscriptionItem = {
      productId: planName.toLowerCase().replace(" ", "-"), // e.g., basic-membership
      name: planName,
      price: price,
      quantity: 1,
      type: "subscription", // Mark as a subscription
    };

    addToCart(subscriptionItem); // Add to cart using context
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        {/* Basic Membership Card */}
        <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <div className="ot-pricing-table">
            <div className="icon-main">
              <span className="flaticon-tablet"></span>
            </div>
            <div className="inner-table">
              <h4 className="title-table">Basic</h4>
              <h2>
                <sup className="currency-symbol">{getCurrencySymbol(currencyCode)}</sup>{" "}
                {convertToCurrency(250)}
              </h2>
              <span>Monthly Package</span>
              <div className="details">
                <ul>
                  <li className="active">Email Support (non-license related)</li>
                  <li className="active">10% Discount on all templates</li>
                  <li className="active">Max 5 template downloads</li>
                  <li className="inactive">Priority Support</li>
                  <li className="inactive">Custom Integration Assistance</li>
                </ul>
              </div>
              <button
                onClick={() => handleAddSubscriptionToCart("Basic Membership", 250)}
                className="octf-btn"
              >
                Choose Plan
              </button>
            </div>
          </div>
        </div>

        {/* Pro Membership Card */}
        <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <div className="ot-pricing-table">
            <div className="icon-main">
              <span className="flaticon-report"></span>
            </div>
            <div className="inner-table">
              <h4 className="title-table">Pro</h4>
              <h2>
                <sup className="currency-symbol">{getCurrencySymbol(currencyCode)}</sup>{" "}
                {convertToCurrency(500)}
              </h2>
              <span>Monthly Package</span>
              <div className="details">
                <ul>
                  <li className="active">Email Support (non-license related)</li>
                  <li className="active">10% Discount on all templates</li>
                  <li className="active">Max 10 template downloads</li>
                  <li className="active">Priority Support</li>
                  <li className="inactive">Custom Integration Assistance</li>
                </ul>
              </div>
              <button
                onClick={() => handleAddSubscriptionToCart("Pro Membership", 500)}
                className="octf-btn"
              >
                Choose Plan
              </button>
            </div>
          </div>
        </div>

        {/* Exclusive Membership Card */}
        <div className="col-lg-4 col-md-6">
          <div className="ot-pricing-table">
            <div className="icon-main">
              <span className="flaticon-ui"></span>
            </div>
            <div className="inner-table">
              <h4 className="title-table">Exclusive</h4>
              <h2>
                <sup className="currency-symbol">{getCurrencySymbol(currencyCode)}</sup>{" "}
                {convertToCurrency(1500)}
              </h2>
              <span>Monthly Package</span>
              <div className="details">
                <ul>
                  <li className="active">Email Support (non-license related)</li>
                  <li className="active">20% Discount on all templates</li>
                  <li className="active">Max 10 template downloads</li>
                  <li className="active">Priority Support</li>
                  <li className="active">Custom Integration Assistance</li>
                </ul>
              </div>
              <button
                onClick={() => handleAddSubscriptionToCart("Exclusive Membership", 1500)}
                className="octf-btn"
              >
                Choose Plan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicePricingCards;
