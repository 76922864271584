// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.web-development-main-heading {
  font-size: 40px;
  font-weight: 700;
}

.tab-gaps {
  gap: 15px;
}

.tab-video {
  padding: 120px 150px 120px 150px;
}

.web-dev-tabs-heading {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1400px) {
    .tab-video {
        padding: 120px 100px 120px 100px;
      }
}

@media (max-width: 1200px) {
    .tab-video {
        padding: 120px 100px 120px 100px;
      }
}

@media (max-width: 1000px) {
    .tab-video {
        padding: 120px 50px 120px 50px;
      }
}

@media (max-width: 768px) {
  .web-dev-tabs-heading {
    flex-direction: column;
  }

  .web-dev-tab-link {
    width: 100%;
    margin: 5px 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/WebDevelopment.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;IACI;QACI,gCAAgC;MAClC;AACN;;AAEA;IACI;QACI,gCAAgC;MAClC;AACN;;AAEA;IACI;QACI,8BAA8B;MAChC;AACN;;AAEA;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,WAAW;IACX,aAAa;EACf;AACF","sourcesContent":[".web-development-main-heading {\r\n  font-size: 40px;\r\n  font-weight: 700;\r\n}\r\n\r\n.tab-gaps {\r\n  gap: 15px;\r\n}\r\n\r\n.tab-video {\r\n  padding: 120px 150px 120px 150px;\r\n}\r\n\r\n.web-dev-tabs-heading {\r\n  display: flex;\r\n  flex-direction: row;\r\n}\r\n\r\n@media (max-width: 1400px) {\r\n    .tab-video {\r\n        padding: 120px 100px 120px 100px;\r\n      }\r\n}\r\n\r\n@media (max-width: 1200px) {\r\n    .tab-video {\r\n        padding: 120px 100px 120px 100px;\r\n      }\r\n}\r\n\r\n@media (max-width: 1000px) {\r\n    .tab-video {\r\n        padding: 120px 50px 120px 50px;\r\n      }\r\n}\r\n\r\n@media (max-width: 768px) {\r\n  .web-dev-tabs-heading {\r\n    flex-direction: column;\r\n  }\r\n\r\n  .web-dev-tab-link {\r\n    width: 100%;\r\n    margin: 5px 0;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
