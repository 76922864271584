// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PROFILE PAGE STYLES */
.profile-page {
    display: flex;
    height: calc(100vh - 150px); /* Full viewport height minus header/footer */
    overflow-y: hidden;
    align-items: stretch; /* Ensures both sidebar and content take full height */
  }
  
  .profile-sidebar {
    flex: 0 0 100px; /* Fixed width for sidebar */
    height: 100%;
    overflow-y: auto; /* Scrollable sidebar */
    background-color: #f4f4f4;
    padding: 1rem;
    border-right: 1px solid #dee2e6;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .profile-content {
    flex: 1 1; /* Takes up remaining space */
    padding: 20px;
    overflow-y: auto; /* Scrollable content area */
    background-color: #fff; /* Optional background color for contrast */
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Profile.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;IACI,aAAa;IACb,2BAA2B,EAAE,6CAA6C;IAC1E,kBAAkB;IAClB,oBAAoB,EAAE,sDAAsD;EAC9E;;EAEA;IACE,eAAe,EAAE,4BAA4B;IAC7C,YAAY;IACZ,gBAAgB,EAAE,uBAAuB;IACzC,yBAAyB;IACzB,aAAa;IACb,+BAA+B;IAC/B,wCAAwC;EAC1C;;EAEA;IACE,SAAO,EAAE,6BAA6B;IACtC,aAAa;IACb,gBAAgB,EAAE,4BAA4B;IAC9C,sBAAsB,EAAE,2CAA2C;EACrE","sourcesContent":["/* PROFILE PAGE STYLES */\r\n.profile-page {\r\n    display: flex;\r\n    height: calc(100vh - 150px); /* Full viewport height minus header/footer */\r\n    overflow-y: hidden;\r\n    align-items: stretch; /* Ensures both sidebar and content take full height */\r\n  }\r\n  \r\n  .profile-sidebar {\r\n    flex: 0 0 100px; /* Fixed width for sidebar */\r\n    height: 100%;\r\n    overflow-y: auto; /* Scrollable sidebar */\r\n    background-color: #f4f4f4;\r\n    padding: 1rem;\r\n    border-right: 1px solid #dee2e6;\r\n    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);\r\n  }\r\n  \r\n  .profile-content {\r\n    flex: 1; /* Takes up remaining space */\r\n    padding: 20px;\r\n    overflow-y: auto; /* Scrollable content area */\r\n    background-color: #fff; /* Optional background color for contrast */\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
