import React from "react";
import { Link } from "react-router-dom";
import "./SectionNeedAConsultation.css";

const SectionNeedAConsultation = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 text-center">
          <p className="consultation-text">
            Drop us a line! We are here to answer your questions 24/7
          </p>
          <h2 className="consultation-heading">NEED A CONSULTATION?</h2>
          <div className="contact-us-button">
            <Link to="/contact" className="contact-button">
              CONTACT US
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionNeedAConsultation;
