// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscription-success {
    padding: 80px 0;
    text-align: center;
  }
  
  .success-icon {
    font-size: 80px;
    color: #4caf50; /* Green for success */
    margin-bottom: 20px;
  }
  
  .subscription-success h2 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .plan-details {
    margin-top: 30px;
  }
  
  .plan-details h4, .next-steps h4 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .plan-details ul, .next-steps ul {
    list-style-type: none;
    padding: 0;
  }
  
  .plan-details li, .next-steps li {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .octf-btn {
    display: inline-block;
    margin-top: 30px;
    padding: 12px 30px;
    font-size: 16px;
    background-color: #0077cc;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .octf-btn:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/SubscriptionSuccess.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,cAAc,EAAE,sBAAsB;IACtC,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;IACrB,UAAU;EACZ;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;IACrB,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,WAAW;IACX,qBAAqB;IACrB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".subscription-success {\r\n    padding: 80px 0;\r\n    text-align: center;\r\n  }\r\n  \r\n  .success-icon {\r\n    font-size: 80px;\r\n    color: #4caf50; /* Green for success */\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  .subscription-success h2 {\r\n    font-size: 36px;\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  .plan-details {\r\n    margin-top: 30px;\r\n  }\r\n  \r\n  .plan-details h4, .next-steps h4 {\r\n    font-size: 24px;\r\n    margin-bottom: 15px;\r\n  }\r\n  \r\n  .plan-details ul, .next-steps ul {\r\n    list-style-type: none;\r\n    padding: 0;\r\n  }\r\n  \r\n  .plan-details li, .next-steps li {\r\n    font-size: 18px;\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  .octf-btn {\r\n    display: inline-block;\r\n    margin-top: 30px;\r\n    padding: 12px 30px;\r\n    font-size: 16px;\r\n    background-color: #0077cc;\r\n    color: #fff;\r\n    text-decoration: none;\r\n    border-radius: 5px;\r\n  }\r\n  \r\n  .octf-btn:hover {\r\n    background-color: #0056b3;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
