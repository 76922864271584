// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.partners-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.partners-slide-inner {
  width: 100%;
  height: auto;
  max-width: 150px;
  margin: auto;
}

.partners-slide-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.slick-prev:before,
.slick-next:before {
  color: #000;
}

.partner-carousel-heading {
  font-size: 36px;
  line-height: 32px;
  font-weight: bold;
  margin-bottom: 60px;
  text-align: center;
}

.slick-track {
  margin: 0 0 30px 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/PartnerCarousel/PartnerCarousel.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;;EAEE,WAAW;AACb;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".partners-slide {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  padding: 10px;\r\n}\r\n\r\n.partners-slide-inner {\r\n  width: 100%;\r\n  height: auto;\r\n  max-width: 150px;\r\n  margin: auto;\r\n}\r\n\r\n.partners-slide-image {\r\n  width: 100%;\r\n  height: auto;\r\n  object-fit: contain;\r\n}\r\n\r\n.slick-prev:before,\r\n.slick-next:before {\r\n  color: #000;\r\n}\r\n\r\n.partner-carousel-heading {\r\n  font-size: 36px;\r\n  line-height: 32px;\r\n  font-weight: bold;\r\n  margin-bottom: 60px;\r\n  text-align: center;\r\n}\r\n\r\n.slick-track {\r\n  margin: 0 0 30px 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
