// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* File: components/ProductCard.css or another CSS file */

.featured-products {
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  margin-top: 20px;
}

.featured-products h2 {
  font-size: 24px;
  margin-bottom: 40px;
  text-align: center;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.product-item {
  width: calc(33.33% - 10px);
  padding: 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
}

.product-item img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.product-item h3 {
  font-size: 18px;
  margin: 10px 0;
}

.product-item p {
  font-size: 16px;
  color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/components/ProductsFeatured/ProductsFeatured.css"],"names":[],"mappings":"AAAA,yDAAyD;;AAEzD;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,SAAS;AACX;;AAEA;EACE,0BAA0B;EAC1B,aAAa;EACb,gBAAgB;EAChB,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,WAAW;AACb","sourcesContent":["/* File: components/ProductCard.css or another CSS file */\r\n\r\n.featured-products {\r\n  padding: 20px;\r\n  background: #f9f9f9;\r\n  border-radius: 8px;\r\n  margin-top: 20px;\r\n}\r\n\r\n.featured-products h2 {\r\n  font-size: 24px;\r\n  margin-bottom: 40px;\r\n  text-align: center;\r\n}\r\n\r\n.product-list {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  gap: 15px;\r\n}\r\n\r\n.product-item {\r\n  width: calc(33.33% - 10px);\r\n  padding: 10px;\r\n  background: #fff;\r\n  border: 1px solid #ddd;\r\n  border-radius: 8px;\r\n  text-align: center;\r\n}\r\n\r\n.product-item img {\r\n  max-width: 100%;\r\n  height: auto;\r\n  border-radius: 5px;\r\n}\r\n\r\n.product-item h3 {\r\n  font-size: 18px;\r\n  margin: 10px 0;\r\n}\r\n\r\n.product-item p {\r\n  font-size: 16px;\r\n  color: #333;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
