// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PROFILE SIDEBAR STYLES */
.profile-sidebar {
    padding: 1rem;
    background-color: #f4f4f4;
    height: 100vh;
    width: 100px;
    position: relative;
    top: 0;
    left: 0;
    border-right: 1px solid #dee2e6;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .profile-sidebar ul {
    list-style: none;
    padding: 0;
    text-align: center;
    font-size: 20px;
  }
  
  .profile-sidebar ul li {
    margin-bottom: 2px;
    padding: 10px;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
    border-radius: 999px;
  }
  
  .profile-sidebar ul li.active,
  .profile-sidebar ul li:hover {
    background-color: #4CAF50; /* Green shade for profile section */
    color: #fff;
  }
  
  .profile-sidebar ul li svg {
    font-size: 24px; /* Size for the icons */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ProfileComponents/ProfileSidebar/ProfileSidebar.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;IACI,aAAa;IACb,yBAAyB;IACzB,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,+BAA+B;IAC/B,wCAAwC;EAC1C;;EAEA;IACE,gBAAgB;IAChB,UAAU;IACV,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,aAAa;IACb,eAAe;IACf,uCAAuC;IACvC,oBAAoB;EACtB;;EAEA;;IAEE,yBAAyB,EAAE,oCAAoC;IAC/D,WAAW;EACb;;EAEA;IACE,eAAe,EAAE,uBAAuB;EAC1C","sourcesContent":["/* PROFILE SIDEBAR STYLES */\r\n.profile-sidebar {\r\n    padding: 1rem;\r\n    background-color: #f4f4f4;\r\n    height: 100vh;\r\n    width: 100px;\r\n    position: relative;\r\n    top: 0;\r\n    left: 0;\r\n    border-right: 1px solid #dee2e6;\r\n    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);\r\n  }\r\n  \r\n  .profile-sidebar ul {\r\n    list-style: none;\r\n    padding: 0;\r\n    text-align: center;\r\n    font-size: 20px;\r\n  }\r\n  \r\n  .profile-sidebar ul li {\r\n    margin-bottom: 2px;\r\n    padding: 10px;\r\n    cursor: pointer;\r\n    transition: background 0.3s, color 0.3s;\r\n    border-radius: 999px;\r\n  }\r\n  \r\n  .profile-sidebar ul li.active,\r\n  .profile-sidebar ul li:hover {\r\n    background-color: #4CAF50; /* Green shade for profile section */\r\n    color: #fff;\r\n  }\r\n  \r\n  .profile-sidebar ul li svg {\r\n    font-size: 24px; /* Size for the icons */\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
