// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-us-button{
    text-decoration: none !important;
    background-color: #0077cc !important;
    border: 1px solid #0077cc;
    color: #fff;
    padding: 15px 40px;
    font-weight: 600;
}

.contact-us-button:hover{
    background-color: #fff !important;
    color: #0077cc;
}
`, "",{"version":3,"sources":["webpack://./src/components/SectionConsultation/SectionConsultation.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,oCAAoC;IACpC,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,iCAAiC;IACjC,cAAc;AAClB","sourcesContent":[".contact-us-button{\r\n    text-decoration: none !important;\r\n    background-color: #0077cc !important;\r\n    border: 1px solid #0077cc;\r\n    color: #fff;\r\n    padding: 15px 40px;\r\n    font-weight: 600;\r\n}\r\n\r\n.contact-us-button:hover{\r\n    background-color: #fff !important;\r\n    color: #0077cc;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
