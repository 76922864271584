// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-to-blog .btn {
  display: block;
  width: 100%;
  margin: 0;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
}

.primary-sidebar{
  min-width: 255px;
}

.sidebar-link {
  background: none !important;
  color: #000;
  margin: 0;
}

.sidebar-menu-item{
  margin: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/BlogSidebar/BlogSidebar.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW;EACX,SAAS;EACT,aAAa;EACb,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;EAC3B,WAAW;EACX,SAAS;AACX;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".back-to-blog .btn {\r\n  display: block;\r\n  width: 100%;\r\n  margin: 0;\r\n  padding: 10px;\r\n  text-align: center;\r\n  box-sizing: border-box;\r\n}\r\n\r\n.primary-sidebar{\r\n  min-width: 255px;\r\n}\r\n\r\n.sidebar-link {\r\n  background: none !important;\r\n  color: #000;\r\n  margin: 0;\r\n}\r\n\r\n.sidebar-menu-item{\r\n  margin: 0 !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
