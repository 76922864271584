// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/AdminProductTags.css */

.admin-product-tags {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-product-tags h1 {
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 1.2rem;
}

.add-tag {
  display: flex;
  margin-bottom: 1rem;
}

.add-tag input {
  flex: 1 1;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
}

.add-tag button {
  padding: 0.5rem 1rem;
  background-color: #0077cc;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.add-tag button:hover {
  background-color: #0063ce;
}

.admin-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.admin-table th,
.admin-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.admin-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.admin-table .action-icon {
  cursor: pointer;
  color: #0077cc;
  transition: color 0.3s;
}

.admin-table .action-icon:hover {
  color: #0063ce;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminProductTags/AdminProductTags.css"],"names":[],"mappings":"AAAA,wCAAwC;;AAExC;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,SAAO;EACP,eAAe;EACf,sBAAsB;EACtB,0BAA0B;AAC5B;;AAEA;EACE,oBAAoB;EACpB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;;EAEE,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["/* src/components/AdminProductTags.css */\r\n\r\n.admin-product-tags {\r\n  padding: 20px;\r\n  background-color: #fff;\r\n  border-radius: 8px;\r\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.admin-product-tags h1 {\r\n  font-size: 1.3rem;\r\n  font-weight: 700;\r\n  margin-bottom: 1.2rem;\r\n}\r\n\r\n.add-tag {\r\n  display: flex;\r\n  margin-bottom: 1rem;\r\n}\r\n\r\n.add-tag input {\r\n  flex: 1;\r\n  padding: 0.5rem;\r\n  border: 1px solid #ccc;\r\n  border-radius: 4px 0 0 4px;\r\n}\r\n\r\n.add-tag button {\r\n  padding: 0.5rem 1rem;\r\n  background-color: #0077cc;\r\n  color: white;\r\n  border: none;\r\n  border-radius: 0 4px 4px 0;\r\n  cursor: pointer;\r\n}\r\n\r\n.add-tag button:hover {\r\n  background-color: #0063ce;\r\n}\r\n\r\n.admin-table {\r\n  width: 100%;\r\n  border-collapse: collapse;\r\n  margin-top: 1rem;\r\n}\r\n\r\n.admin-table th,\r\n.admin-table td {\r\n  border: 1px solid #ddd;\r\n  padding: 8px;\r\n  text-align: center;\r\n}\r\n\r\n.admin-table th {\r\n  background-color: #f4f4f4;\r\n  font-weight: bold;\r\n}\r\n\r\n.admin-table .action-icon {\r\n  cursor: pointer;\r\n  color: #0077cc;\r\n  transition: color 0.3s;\r\n}\r\n\r\n.admin-table .action-icon:hover {\r\n  color: #0063ce;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
