// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/ProductTable.css */

.product-table-container {
    margin-top: 2rem;
  }
  
  .product-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
  }
  
  .product-table th,
  .product-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .product-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .product-table td img.product-image {
    width: 50px;
    height: auto;
    border-radius: 5px;
  }
  
  .product-table .action-icon {
    cursor: pointer;
    color: #0077cc;
    transition: color 0.3s;
  }
  
  .product-table .action-icon:hover {
    color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ProductTable/ProductTable.css"],"names":[],"mappings":"AAAA,oCAAoC;;AAEpC;IACI,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,gBAAgB;EAClB;;EAEA;;IAEE,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,cAAc;IACd,sBAAsB;EACxB;;EAEA;IACE,cAAc;EAChB","sourcesContent":["/* src/components/ProductTable.css */\r\n\r\n.product-table-container {\r\n    margin-top: 2rem;\r\n  }\r\n  \r\n  .product-table {\r\n    width: 100%;\r\n    border-collapse: collapse;\r\n    margin-top: 1rem;\r\n  }\r\n  \r\n  .product-table th,\r\n  .product-table td {\r\n    border: 1px solid #ddd;\r\n    padding: 8px;\r\n    text-align: center;\r\n  }\r\n  \r\n  .product-table th {\r\n    background-color: #f4f4f4;\r\n    font-weight: bold;\r\n  }\r\n  \r\n  .product-table td img.product-image {\r\n    width: 50px;\r\n    height: auto;\r\n    border-radius: 5px;\r\n  }\r\n  \r\n  .product-table .action-icon {\r\n    cursor: pointer;\r\n    color: #0077cc;\r\n    transition: color 0.3s;\r\n  }\r\n  \r\n  .product-table .action-icon:hover {\r\n    color: #0056b3;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
